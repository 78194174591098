import { b2x } from '@b2x/react/src';
import React from 'react';

export interface SalesforceChatProps {}

export const SalesforceChat = (props: SalesforceChatProps) => {
  React.useEffect(() => {
    const initESW = (gslbBaseURL: string | null) => {
      embedded_svc.settings.displayHelpButton = true; //Or false
      embedded_svc.settings.language = 'it'; //For example, enter 'en' or 'en-US'
      embedded_svc.settings.defaultMinimizedText = 'PARLA CON NOI!'; //(Defaults to Chat with an Expert)
      embedded_svc.settings.disabledMinimizedText = 'Chat Offline'; //(Defaults to Agent Offline)
      embedded_svc.settings.loadingText = 'Loading'; //(Defaults to Loading)

      embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      embedded_svc.settings.entryFeature = 'LiveAgent';

      embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      embedded_svc.settings.entryFeature = 'LiveAgent';

      embedded_svc.init(
        'https://nestlecesomni.my.salesforce.com',
        'https://nestlecesomni.my.salesforce-sites.com/liveAgentSetupFlow',
        gslbBaseURL,
        '00D0900000460T3',
        'IT_Purina_Embedded_Service',
        {
          baseLiveAgentContentURL: 'https://c.la3-c1-fra.salesforceliveagent.com/content',
          baseLiveAgentURL: 'https://d.la11-core1.sfdc-yzvdd4.salesforceliveagent.com/chat',
          buttonId: '57309000000DBWD',
          deploymentId: '572090000008pYA',
          eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I090000004krpEAA_17835423492',
          isOfflineSupportEnabled: false,
        }
      );
    };

    b2x
      .addScript({
        id: 'salesforce-chat',
        src: 'https://nestlecesomni.my.salesforce.com/embeddedservice/5.0/esw.min.js',
      })
      .finally(() => {
        if (!window.embedded_svc) {
          const s = document.createElement('script');
          s.setAttribute('src', 'https://nestlecesomni.my.salesforce.com/embeddedservice/5.0/esw.min.js');
          s.onload = () => {
            initESW(null);
          };
          document.body.appendChild(s);
        } else {
          initESW('https://service.force.com');
        }
      });
  }, []);

  return <></>;
};
