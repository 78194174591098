import './ProductTile.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { Button, ButtonVariant } from './Button';
import { t } from './i18n/i18n';
import { IconName, IconSize } from './Icon';
import { ProducTiletStickers } from './ProductTileStickers';
import { Slider } from './slider/Slider';

export type ProductTileProps = b2x.ProductTileProps;

export const ProductTile = (props: ProductTileProps) => {
  return <b2x.ProductTile className={'card bg-white mb-3 mb-lg-0'} {...props} enableExtraInfo />;
};

export type ProductTileBasicInfoProps = b2x.ProductTileBasicInfoProps;

export const ProductTileBasicInfo = (props: ProductTileBasicInfoProps) => (
  <b2x.ProductTileBasicInfo {...props} customComponent={CustomProductTileBasicInfo} />
);

const CustomProductTileBasicInfo = ({
  handleLinkClick,
  imageFormat,
  product,
  productsPerRow,
  sku,
}: b2x.ProductTileBasicInfoProps) => {
  const selectedSku = React.useMemo(
    () => product.skus?.find((productSku) => productSku.id === sku?.id),
    [product.skus, sku]
  );

  const { bestPriceDiscountPercentage, bestPriceValue, crossedOutPrice, discountPercentage, isPriceInRange, price } =
    b2x.usePrice(product.priceRange, sku?.price);

  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <div className="product-tile-basic-info">
      <div className="product-tile-media-container position-relative bg-gray-100">
        <b2x.router.Link className="d-block bg-gray-100 p-3 stretched-link" onClick={handleLinkClick} to={product.url}>
          <b2x.EqualHeightElement name={`product-image-${productsPerRow}`}>
            <b2x.Image
              alt={product.name}
              aspectRatio={b2x.appConfig.productImageAspectRatio}
              className="card-img-top"
              fluid
              format={imageFormat}
              src={selectedSku?.image ? selectedSku.image.src : product.image?.src}
            />
          </b2x.EqualHeightElement>
        </b2x.router.Link>
        {discountPercentage && (
          <div
            className={classnames(
              'discount-percentage bg-yellow text-white fw-bold',
              { small: b2x.untilBreakpoint('xs', currentBreakpoint) },
              { 'fs-5': !b2x.untilBreakpoint('xs', currentBreakpoint) }
            )}
          >{`-${b2x.formatFloatPercentage(discountPercentage)}%`}</div>
        )}
        {b2x.appConfig.enableWishlist && (
          <div className="wishlist-action-container position-absolute top-0 end-0 p-3" style={{ zIndex: 100 }}>
            <b2x.WishlistButtonHelper product={product} sku={sku}>
              {({ handleWishlistButtonClick, inWishlist }) => (
                <Button
                  aria-label={t('accessibility.productTile.wishlist', { productName: product.name })}
                  iconEnd={{ name: inWishlist ? 'wishlist-full' : 'wishlist', size: 25 }}
                  onClick={handleWishlistButtonClick}
                  type="button"
                  variant="blank"
                />
              )}
            </b2x.WishlistButtonHelper>
          </div>
        )}
        <div
          className="position-absolute bottom-0 start-0 w-100 px-2 px-md-3"
          style={{ pointerEvents: 'none', zIndex: 999 }}
        >
          <ProducTiletStickers product={product} selectedVariant={product} />
        </div>
      </div>
      <div className="pt-2 p-md-3 pb-md-0">
        <b2x.Row gap={2}>
          <b2x.Col size={12}>
            <b2x.EqualHeightElement name={`product-name-${productsPerRow}`}>
              {product.brand && <h6 className="text-uppercase small">{product.brand.name}</h6>}
              <h6 className={classnames({ small: b2x.untilBreakpoint('sm', currentBreakpoint) }, 'fw-bold mb-0')}>
                <b2x.router.Link className="text-reset text-decoration-none" onClick={handleLinkClick} to={product.url}>
                  {product.name}
                </b2x.router.Link>
              </h6>
              <b2x.SummaryReviewsPreview
                className="mt-1 extra-small lh-1"
                productId={product.id}
                productSummaryReviews={product.summaryReviews}
              />
            </b2x.EqualHeightElement>
          </b2x.Col>
          <b2x.Col size={12}>
            <b2x.EqualHeightElement name={`product-price-${productsPerRow}`}>
              <div className="product-tile-price-container">
                <b2x.PriceBlock
                  hiddenDiscountPercentage
                  priceHelper={{
                    crossedOutPrice: crossedOutPrice,
                    discountPercentage: discountPercentage,
                    isPriceInRange: isPriceInRange,
                    price: price,
                  }}
                />
                {b2x.appConfig.enableBestPrice && bestPriceValue && (
                  <div className="extra-small fw-light">
                    <b2x.BestPrice
                      // enableZeroPercentage
                      priceHelper={{
                        bestPriceDiscountPercentage: bestPriceDiscountPercentage,
                        bestPriceValue: bestPriceValue,
                        crossedOutPrice: crossedOutPrice,
                        price: price,
                      }}
                    />
                  </div>
                )}
              </div>
            </b2x.EqualHeightElement>
          </b2x.Col>
        </b2x.Row>
      </div>
    </div>
  );
};

export type ProductTileExtraInfoProps = b2x.ProductTileExtraInfoProps;

export const ProductTileExtraInfo = (props: ProductTileExtraInfoProps) => (
  <b2x.ProductTileExtraInfo customComponent={CustomProductTileExtraInfo} {...props} />
);

const CustomProductTileExtraInfo = ({
  fieldsHelper,
  product,
  productsPerRow,
  selectedProductVariant,
  selectedSku,
}: ProductTileExtraInfoProps) => {
  const { inWishlist } = b2x.useWishlistButtonHelper({
    product: selectedProductVariant ?? product,
    sku: selectedSku,
  });

  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <div className="product-tile-extra-info pt-2 p-md-3">
      <b2x.EqualHeightElement name={`product-tile-extra-info-${productsPerRow}`}>
        <b2x.Row gap={{ lg: 3, xs: 2 }}>
          <b2x.Col size={12}>
            <b2x.FormGroup {...fieldsHelper.productVariants.formGroup} label={undefined} noMarginBottom>
              <Slider slidesPerView={'auto'} spaceBetween={8}>
                {fieldsHelper.productVariants.formFields.map((formField, index) => (
                  <SwiperSlide key={formField.radio.id}>
                    <b2x.Radio
                      key={formField.productVariant.id}
                      noMargin
                      {...formField.radio}
                      inline
                      labelClassName="mb-1 small text-secondary border border-1 bg-gray-200 border-gray-200 py-1 px-2"
                    >
                      {formField.productVariant.skus?.map((sku) => sku.name)}
                    </b2x.Radio>
                  </SwiperSlide>
                ))}
              </Slider>
            </b2x.FormGroup>
          </b2x.Col>
          <b2x.Col size={12}>
            <b2x.EqualHeightElement name={`product-tile-mimmo-${productsPerRow}`}>
              <div className="d-flex h-100 align-items-end">
                <div className="w-100">
                  <b2x.Row>
                    {selectedSku?.state === 'AVAILABLE' && (
                      <b2x.Col className="d-none d-md-block" size={'auto'}>
                        <b2x.FormGroup {...fieldsHelper.quantity.formGroup} label={undefined} noMarginBottom>
                          <b2x.Select
                            {...fieldsHelper.quantity.select}
                            aria-label={t('accessibility.select.quantity', { productName: product.name })}
                            className="quantity-select text-center"
                            includeEmptyOption={false}
                            placeholder={undefined}
                          />
                        </b2x.FormGroup>
                      </b2x.Col>
                    )}
                    <b2x.Col size={{ md: '', xs: 12 }}>
                      {selectedSku?.state !== 'AVAILABLE' &&
                        b2x.appConfig.enableProductAvailabilityEmailNotification && (
                          <div className="mb-2">
                            <b className="small ">
                              {inWishlist ? t('misc.availabilityNotification') : t('misc.productNotAvailable')}
                            </b>
                          </div>
                        )}
                      <div className="d-grid">
                        <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
                          availabilityEmailNotificationButton={{
                            className: 'availability-email-notification-button',
                            justifyContent: 'center',
                            label: inWishlist
                              ? t('form.addToCartForm.buttons.availabilityEmailNotification.remove.label')
                              : b2x.untilBreakpoint('xs', currentBreakpoint)
                              ? t('form.addToCartForm.buttons.availabilityEmailNotification.addShort.label')
                              : t('form.addToCartForm.buttons.availabilityEmailNotification.addLong.label'),
                          }}
                          fieldsHelper={fieldsHelper}
                          selectedSku={selectedSku}
                          submitButton={{ className: 'submit-button px-0' }}
                        />
                      </div>
                    </b2x.Col>
                  </b2x.Row>
                </div>
              </div>
            </b2x.EqualHeightElement>
          </b2x.Col>
        </b2x.Row>
      </b2x.EqualHeightElement>
    </div>
  );
};
