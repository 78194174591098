import { Div } from '../../../HTMLElement';
import { useSearch } from '../../../useSearch';
import { renderUI } from '../../../util';
import { AccountQuickReorderSubpageProps } from '../AccountQuickReorderSubpage';
import { AccountSubpage } from '../AccountSubpage';

export const AccountQuickReorderSubpageA = (props: AccountQuickReorderSubpageProps) => {
  const { searchResult } = useSearch({ defaultPageSize: 12, withOrderedProducts: true });

  console.log(searchResult);

  return renderUI({
    bs5: (
      <AccountSubpage>
        <Div marginBottom={4}>Riordina veloce</Div>
      </AccountSubpage>
    ),
  });
};
