import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Box } from '../Box';
import { Button } from '../Button';
import { Container } from '../Container';
import { LandingPuppyPageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Icon } from '../Icon';
import { useMobileSearchFiltersOffcanvas } from '../MobileSearchFiltersOffcanvas';
import { Page } from './Page';

export interface LandingPuppyProps {}

export const LandingPuppyPage = (props: LandingPuppyProps) => {
  const { session } = b2x.useAppContext();

  const { page, searchResult } = b2x.useSearch<LandingPuppyPageContentType>({
    defaultPageSize: 12,
    pageOptions: {
      populate: {
        content: true,
      },
    },
  });

  const _excludeFacets = ['FACE_GOURMET'];
  const [MobileSearchFiltersOffcanvas, showMobileSearchFiltersOffcanvas] = useMobileSearchFiltersOffcanvas({
    basePath: window.location.pathname,
    excludeFacets: _excludeFacets,
    searchResult: searchResult,
  });
  const [mobileColumn, setMobileColumn] = React.useState<string>('multiple');
  const handleSetMobileListingColumn = React.useCallback((column: 'single' | 'multiple') => {
    setMobileColumn(column);
  }, []);

  const { purinaAssignQualifier } = b2x.useCustomApi();
  const [params] = b2x.router.useSearchParams();
  const token = params.get('token');
  const campagna = params.get('campagna');

  const body = page?.content?.body;

  React.useEffect(() => {
    if (session?.userLogged && token && campagna) {
      purinaAssignQualifier({ campagna, token });
    }
  }, [campagna, purinaAssignQualifier, session?.userLogged, token]);

  return (
    <Page className="landing-puppy-page" noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <div className="d-flex align-items-center">
        {body?.banner?.img && (
          <div className={classnames('h-100 w-100')} style={{ gridColumn: 1, gridRow: 1 }}>
            <b2x.ImageFromContentV2 {...body.banner.img} className="w-100" fluid />
          </div>
        )}
        <div className="message position-absolute w-100 d-none d-lg-block">
          <Container>
            <div
              className={classnames(
                'd-lg-flex',
                'justify-content-start',
                {
                  'justify-content-center': body?.banner?.textAlignment === 'center',
                  'text-center': body?.banner?.textAlignment === 'center',
                },
                {
                  'justify-content-end': body?.banner?.textAlignment === 'right',
                  'text-end': body?.banner?.textAlignment === 'right',
                }
              )}
            >
              <div className="col-lg-7 col-12">
                {body?.banner?.title && (
                  <h4 className={classnames('title display-4 ff-purina')}>{b2x.formatHtml(body.banner.title)}</h4>
                )}
                {body?.banner?.subtitle && <h5 className={classnames('h2')}>{b2x.formatHtml(body.banner.subtitle)}</h5>}
              </div>
            </div>
          </Container>
        </div>
      </div>
      {session?.userLogged ? (
        <>
          {body?.copy && (
            <Container>
              <b2x.Row className="justify-content-center">
                <b2x.Col size={{ xl: 8, xs: 12 }}>
                  <div className="my-5 text-center">{b2x.formatHtml(body.copy)}</div>
                </b2x.Col>
              </b2x.Row>
            </Container>
          )}

          {MobileSearchFiltersOffcanvas}
          <section className="search-content pt-3 pt-lg-5">
            <Container>
              <b2x.Row>
                <b2x.Col size={{ xl: 3, xs: 12 }}>
                  <b2x.SearchFormHelper basePath={window.location.pathname} searchResult={searchResult} submitOnChange>
                    {({ fieldsHelper, formik }) => {
                      return (
                        <>
                          <div className="d-grid d-sm-block d-xl-none mb-3">
                            <Button
                              className="px-2"
                              onClick={showMobileSearchFiltersOffcanvas}
                              type="button"
                              variant="secondary"
                            >
                              <div className="d-flex justify-content-between align-items-center w-100">
                                <div className="me-3">
                                  <Icon className="me-2" name="filter" size={20} />
                                  {t('misc.filterAndOrderBy')}
                                </div>
                                {fieldsHelper.activeFilters.length > 0 && (
                                  <div
                                    className="bg-white text-black rounded-circle px-2 small d-flex align-items-center justify-content-center"
                                    style={{ height: '20px', width: '20px' }}
                                  >
                                    {fieldsHelper.activeFilters.length > 1
                                      ? fieldsHelper.activeFilters.length - 1
                                      : fieldsHelper.activeFilters.length}
                                  </div>
                                )}
                              </div>
                            </Button>
                          </div>
                          <b2x.Div className="search-form-active-filter d-none d-xl-block">
                            {fieldsHelper.activeFilters.length > 0 && (
                              <b2x.Row className="mb-3" gap={{ md: 1, xs: 2 }}>
                                {fieldsHelper.activeFilters.map((activeFilter, index) => (
                                  <b2x.Col
                                    key={activeFilter.filter.name + activeFilter.filter.id}
                                    size={{ lg: 12, xs: 'auto' }}
                                  >
                                    {activeFilter.fromSimpleSearch && (
                                      <small className="py-1">{t('misc.youSearchedFor')}</small>
                                    )}
                                    <div className="d-grid">
                                      <Button
                                        className={classnames('btn-sm extra-small fw-normal py-1 px-2', {
                                          'text-uppercase':
                                            fieldsHelper.activeFilters.length > 1 &&
                                            fieldsHelper.activeFilters.length === index + 1,
                                        })}
                                        iconEnd={{ name: 'delete', size: 14 }}
                                        justifyContent="between"
                                        label={activeFilter.filter.name}
                                        onClick={activeFilter.handleClick}
                                        type="button"
                                        variant="gray-100"
                                      />
                                    </div>
                                  </b2x.Col>
                                ))}
                              </b2x.Row>
                            )}
                          </b2x.Div>
                          <div className="d-none d-xl-block">
                            <b2x.SearchFilters excludeFacets={_excludeFacets} fieldsHelper={fieldsHelper} />
                          </div>
                        </>
                      );
                    }}
                  </b2x.SearchFormHelper>
                </b2x.Col>
                <b2x.Col size={{ xl: 9, xs: 12 }}>
                  {searchResult?.itemsCount && (
                    <b2x.Row className="justify-content-between mb-3 extra-small">
                      <b2x.Col size={'auto'} style={{ alignItems: 'center', display: 'flex' }}>
                        <span>{t('misc.searchItemResult', { count: searchResult.itemsCount })}</span>
                      </b2x.Col>
                      <b2x.Col size={'auto'}>
                        <SortingOptionsDropdown className="d-none d-lg-block" searchResult={searchResult} />
                        <div className="d-block d-md-none">
                          <div className="hstack gap-2">
                            <Button
                              className="p-2"
                              iconStart={{ name: 'two-column-display', size: 14 }}
                              // eslint-disable-next-line react/jsx-no-bind
                              onClick={() => handleSetMobileListingColumn('multiple')}
                              variant="blank"
                            />
                            <div className="vr"></div>
                            <Button
                              className="p-2"
                              iconStart={{ name: 'one-column-display', size: 14 }}
                              // eslint-disable-next-line react/jsx-no-bind
                              onClick={() => handleSetMobileListingColumn('single')}
                              variant="blank"
                            />
                          </div>
                        </div>
                      </b2x.Col>
                    </b2x.Row>
                  )}
                  {searchResult && searchResult.items && (
                    <b2x.Listing name="Search page" products={searchResult.items}>
                      <b2x.ProductsTiles
                        className="mb-5"
                        enableExtraInfo
                        gap={{ lg: 4, xs: 2 }}
                        products={searchResult.items.map((product) => ({ product: product }))}
                        productsPerRow={{
                          lg: 3,
                          md: 2,
                          sm: mobileColumn === 'multiple' ? 2 : 1,
                          xl: 3,
                          xs: mobileColumn === 'multiple' ? 2 : 1,
                          xxl: 3,
                        }}
                      />
                    </b2x.Listing>
                  )}
                  {searchResult && (
                    <b2x.Div display="flex" justifyContent={{ lg: 'end', xs: 'center' }}>
                      <b2x.Pagination
                        currentPage={searchResult.pageNumber}
                        pageOffset={2}
                        showDots
                        singleStepNavigation
                        totalPages={searchResult.pagesCount}
                      />
                    </b2x.Div>
                  )}
                </b2x.Col>
              </b2x.Row>
            </Container>
          </section>
        </>
      ) : (
        <Container>
          {body?.copyForNoLoggedUser && (
            <b2x.Row className="justify-content-center">
              <b2x.Col size={{ xl: 8, xs: 12 }}>
                <div className="my-5 text-center">{b2x.formatHtml(body.copyForNoLoggedUser)}</div>
              </b2x.Col>
            </b2x.Row>
          )}
          <b2x.Row className="justify-content-center">
            <b2x.Col size={{ md: 6, xl: 5, xs: 12 }}>
              <div className="h-100 pb-3 pb-md-5">
                <Box fullHeight>
                  <b2x.LoginForm />
                </Box>
              </div>
            </b2x.Col>
          </b2x.Row>
        </Container>
      )}
    </Page>
  );
};

interface SortingOptionsDropdownProps {
  className?: string;
  searchResult?: b2x.SearchProductsApiDto;
}

const SortingOptionsDropdown = ({ className, searchResult }: SortingOptionsDropdownProps) => {
  const { sortingOptions } = b2x.useSortingOptions([
    { orderBy: 'PRICE', orderingType: 'ASC' },
    { orderBy: 'PRICE', orderingType: 'DESC' },
  ]);

  return (
    <>
      {searchResult && (
        <b2x.Dropdown
          buttonClassname="btn-sm extra-small fw-normal"
          className={classnames('order-dropdown ps-3 border-start border-gray-100', className)}
          label={b2x.formatHtml(
            `${t('misc.orderBy')}: <b>${sortingOptions.find((sortingItem) => sortingItem.active)?.label}</b>`
          )}
          variant="blank"
        >
          {sortingOptions.map((sortingOption) => (
            <b2x.DropdownItem active={sortingOption.active} key={sortingOption.label} onClick={sortingOption.onClick}>
              <span>{sortingOption.label}</span>
            </b2x.DropdownItem>
          ))}
        </b2x.Dropdown>
      )}
    </>
  );
};
