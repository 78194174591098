import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

export interface CountdownProps extends b2x.CountdownProps {}

export const Countdown = ({
  blockClassNames,
  blockStyle,
  classNames,
  labelClassNames,
  style,
  valueClassNames,
  ...otherProps
}: CountdownProps) => {
  return (
    <b2x.Countdown
      {...otherProps}
      blockClassNames={classnames(blockClassNames, 'align-items-center d-flex flex-column p-2 p-md-4')}
      blockStyle={{ flex: 1, ...blockStyle }}
      classNames={classnames(classNames, 'd-flex text-white bg-primary')}
      labelClassNames={classnames(labelClassNames, 'text-uppercase small')}
      valueClassNames={classnames(valueClassNames, 'display-2 fw-bold mb-0')}
    />
  );
};
