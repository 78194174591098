import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import { OffcanvasHeader } from './Offcanvas';

interface MobileSearchFiltersOffcanvasProps extends b2x.OffcanvasProps {
  basePath?: string;
  excludeFacets?: Array<string>;
  searchResult?: b2x.SearchProductsApiDto;
}

const MobileSearchFiltersOffcanvas = ({
  basePath,
  excludeFacets,
  searchResult,
  ...offcanvasProps
}: MobileSearchFiltersOffcanvasProps) => {
  const { sortingOptions } = b2x.useSortingOptions([
    { orderBy: 'PRICE', orderingType: 'ASC' },
    { orderBy: 'PRICE', orderingType: 'DESC' },
  ]);
  return (
    <b2x.Offcanvas {...offcanvasProps}>
      {({ close }) => (
        <>
          <OffcanvasHeader smallPadding title="Filtra" />
          <b2x.OffcanvasBody className="px-3 py-0">
            <b2x.SearchFormHelper
              basePath={basePath}
              className="position-relative h-100"
              searchResult={searchResult}
              submitOnChange
            >
              {({ fieldsHelper, formik }) => {
                const filteredFilters = fieldsHelper.filters.filter(
                  ({ filter }) => !excludeFacets?.includes(filter.code)
                );

                return (
                  <b2x.Drawers itemsClassName="px-3 py-1">
                    <div className="bg-gray-100 p-3 border-bottom">
                      <div className="extra-small text-dark mb-2">{t('misc.orderBy')}</div>
                      <div className="d-flex gap-2 flex-wrap mb-3 pb-3 border-bottom">
                        {sortingOptions.map((sortingOption) => (
                          <Button
                            className="text-nowrap text-lowercase btn-sm fw-normal px-3"
                            key={sortingOption.label}
                            label={sortingOption.label}
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={() => {
                              sortingOption.onClick();
                              close();
                            }}
                            variant={sortingOption.active ? 'outline-secondary' : 'outline-gray-400'}
                          />
                        ))}
                      </div>
                      {fieldsHelper.activeFilters.length > 0 && (
                        <b2x.Row gap={2}>
                          <b2x.Col size={12}>
                            <h6 className="extra-small mb-0">{t('misc.youSearchedFor')}</h6>
                          </b2x.Col>
                          {fieldsHelper.activeFilters.map((activeFilter, index) => (
                            <b2x.Col key={activeFilter.filter.name + activeFilter.filter.id} size={'auto'}>
                              <Button
                                className={classnames('fw-normal btn-sm', {
                                  'text-uppercase':
                                    fieldsHelper.activeFilters.length > 1 &&
                                    fieldsHelper.activeFilters.length === index + 1,
                                })}
                                iconEnd={{ name: 'delete', size: 14 }}
                                justifyContent="between"
                                label={activeFilter.filter.name}
                                onClick={activeFilter.handleClick}
                                type="button"
                                variant="outline-secondary"
                              />
                            </b2x.Col>
                          ))}
                        </b2x.Row>
                      )}
                    </div>

                    <div>
                      <p className="py-3 mb-0 border-bottom extra-small">{t('misc.filterYourSearchResults')}</p>
                    </div>
                    <DrawerSearchFiltersItems close={close} items={filteredFilters} textUppercase />
                  </b2x.Drawers>
                );
              }}
            </b2x.SearchFormHelper>
          </b2x.OffcanvasBody>
        </>
      )}
    </b2x.Offcanvas>
  );
};

interface DrawerBackItemProps {
  label?: string;
}

const DrawerBackItem = ({ label = 'Indietro' }: DrawerBackItemProps) => {
  const { closeActiveDrawer } = b2x.useDrawersContext();

  return (
    <b2x.DrawerButtonItem onClick={closeActiveDrawer}>
      <b2x.DrawerItem
        center={{ center: label }}
        className="extra-small fw-light gap-3"
        end={{ center: <Icon className="invisible" name="small-arrow-left" /> }}
        height={35}
        justifyContent="between"
        start={{ center: <Icon name="small-arrow-left" /> }}
      />
    </b2x.DrawerButtonItem>
  );
};

interface DrawerSearchFiltersItemsProps {
  close(): void;
  fontWeight?: 'medium' | 'light';
  items: Array<b2x.SearchFilter>;
  previousItem?: b2x.SearchFilter;
  textUppercase?: boolean;
}

const DrawerSearchFiltersItems = ({ close, items, previousItem, textUppercase }: DrawerSearchFiltersItemsProps) => {
  return (
    <>
      {items.map((_item) => {
        // TMP: Purina tmp solution for filters search
        // TODO: Remove this when we have a better solution
        const item = _item.filter.id === '32508' && _item.children.length > 0 ? _item.children[0] : _item;
        const hasChildren = item.children.length > 0;
        const checkedChildren = item.children.filter((child) => child.filter.checkType === 'CHECKED');

        return hasChildren ? (
          <b2x.DrawerTriggerItem
            key={item.filter.id}
            newDrawer={
              <Drawer backItemProps={{ label: item.filter.name }}>
                <DrawerSearchFiltersItems close={close} items={item.children} textUppercase />
              </Drawer>
            }
          >
            <b2x.DrawerItem
              className={classnames('small')}
              end={{ center: <Icon name="small-arrow-right" /> }}
              height={55}
              justifyContent="between"
              start={{
                bottom:
                  checkedChildren.length > 0 ? (
                    <div className="extra-small">{checkedChildren.map((child) => child.filter.name).join(', ')}</div>
                  ) : undefined,
                top: (
                  <span
                    className={classnames(
                      item.filter.checkType === 'CHECKED' || item.filter.checkType === 'CHILD_CHECKED'
                        ? 'fw-bold'
                        : 'fw-normal',
                      { 'text-uppercase': textUppercase }
                    )}
                  >
                    {item.filter.name}
                  </span>
                ),
              }}
            />
          </b2x.DrawerTriggerItem>
        ) : (
          <b2x.DrawerCheckboxItem key={item.filter.id} {...item.checkbox}>
            <b2x.DrawerItem
              className={classnames('small', {
                'fw-bold': item.filter.checkType === 'CHECKED',
              })}
              end={{
                center: item.filter.checkType === 'CHECKED' ? <Icon name="delete" /> : undefined,
              }}
              height={55}
              justifyContent="between"
              start={{ center: item.filter.name }}
            />
          </b2x.DrawerCheckboxItem>
        );
      })}
      {previousItem && (
        <b2x.DrawerCheckboxItem {...previousItem.checkbox}>
          <b2x.DrawerItem
            className={classnames('small', {
              'fw-bold': previousItem.filter.checkType === 'CHECKED',
            })}
            end={{
              center: previousItem.filter.checkType === 'CHECKED' ? <Icon name="delete" /> : undefined,
            }}
            height={55}
            justifyContent="between"
            start={{ center: 'Seleziona tutto' }}
          />
        </b2x.DrawerCheckboxItem>
      )}
    </>
  );
};

interface DrawerProps {
  backItemProps?: DrawerBackItemProps;
  children: React.ReactNode;
}

const Drawer = ({ backItemProps, children }: DrawerProps) => {
  return (
    <div className="d-flex flex-column h-100 overflow-auto">
      <DrawerBackItem {...backItemProps} />
      <div className="bg-white flex-grow-1 overflow-auto">{children}</div>
    </div>
  );
};

export const useMobileSearchFiltersOffcanvas = (props?: MobileSearchFiltersOffcanvasProps) =>
  b2x.useStaticModal(MobileSearchFiltersOffcanvas, 'offcanvas', props);
