import './LandingPrivateSales.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Box } from '../Box';
import { Button } from '../Button';
import { Container } from '../Container';
import { LandingPrivateSalesPageContentType } from '../contentTypes';
import { Countdown } from '../Countdown';
import { t } from '../i18n/i18n';
import { Icon } from '../Icon';
import { useMobileSearchFiltersOffcanvas } from '../MobileSearchFiltersOffcanvas';
import { Page } from './Page';

/**
 * Phase1 = le Private sales devono ancora arrivare
 * Phase2 = le private sales sono in corso (per gli utenti loggati)
 * Phase3 = le private sales sono terminate e la pagina è attiva per tutti
 */
type UseCase = 'phase1Guest' | 'phase1Logged' | 'phase2Guest' | 'phase2Logged' | 'phase3';

export interface LandingPrivateSalesProps {}

export const LandingPrivateSales = (props: LandingPrivateSalesProps) => {
  const { session } = b2x.useAppContext();
  const { getFixedPagePath } = b2x.useAppStaticContext();

  const { page, searchResult } = b2x.useSearch<LandingPrivateSalesPageContentType>({
    defaultPageSize: 12,
    pageOptions: {
      populate: {
        content: true,
      },
    },
  });

  const _excludeFacets = ['FACE_GOURMET'];
  const [MobileSearchFiltersOffcanvas, showMobileSearchFiltersOffcanvas] = useMobileSearchFiltersOffcanvas({
    basePath: window.location.pathname,
    excludeFacets: _excludeFacets,
    searchResult: searchResult,
  });
  const [mobileColumn, setMobileColumn] = React.useState<string>('multiple');
  const handleSetMobileListingColumn = React.useCallback((column: 'single' | 'multiple') => {
    setMobileColumn(column);
  }, []);

  const goliveDateForLoggedUsers = React.useMemo(
    () => b2x.parseDateTimeFromContent(page?.content?.body.goliveDateForLoggedUsers),
    [page?.content?.body.goliveDateForLoggedUsers]
  );

  const countdownForLoggedUsers = b2x.useCountdown(goliveDateForLoggedUsers);

  const goliveDateForAllUsers = React.useMemo(
    () => b2x.parseDateTimeFromContent(page?.content?.body.goliveDateForAllUsers),
    [page?.content?.body.goliveDateForAllUsers]
  );

  const countdownForAllUsers = b2x.useCountdown(goliveDateForAllUsers);

  const useCase = React.useMemo<UseCase | undefined>(() => {
    if (
      countdownForLoggedUsers?.completed !== undefined &&
      countdownForAllUsers?.completed !== undefined &&
      session?.userLogged !== undefined
    ) {
      if (!countdownForLoggedUsers.completed) {
        if (session.userLogged) {
          return 'phase1Logged';
        } else {
          return 'phase1Guest';
        }
      } else if (!countdownForAllUsers.completed) {
        if (session.userLogged) {
          return 'phase2Logged';
        } else {
          return 'phase2Guest';
        }
      } else {
        return 'phase3';
      }
    } else {
      return undefined;
    }
  }, [countdownForAllUsers?.completed, countdownForLoggedUsers?.completed, session?.userLogged]);

  return (
    <>
      {useCase !== undefined && (useCase === 'phase2Logged' || useCase === 'phase3') ? (
        <Page className="private-sales-page" noPaddingTop thingsToLoadBeforePageReady={[page, useCase]}>
          <div className="d-flex align-items-center main-banner">
            {page?.content?.body.banner?.img && (
              <div className={classnames('h-100 w-100')} style={{ gridColumn: 1, gridRow: 1 }}>
                <b2x.ImageFromContentV2 {...page.content.body.banner.img} className="w-100" fluid />
              </div>
            )}
            <div className="message w-100 mt-lg-0 mt-4">
              <Container>
                <div
                  className={classnames(
                    'd-flex justify-content-center text-center',
                    'justify-content-lg-start text-lg-start',
                    {
                      'justify-content-lg-center': page?.content?.body.banner?.textAlignment === 'center',
                      'text-lg-center': page?.content?.body.banner?.textAlignment === 'center',
                    },
                    {
                      'justify-content-lg-end': page?.content?.body.banner?.textAlignment === 'right',
                      'text-lg-end': page?.content?.body.banner?.textAlignment === 'right',
                    }
                  )}
                >
                  <div className="col-lg-7 col-12">
                    {page?.content?.body.banner?.title && (
                      <h1
                        className={classnames(
                          `title display-1 mb-3 ff-purina text-${page.content.body.banner.titleColor}`
                        )}
                      >
                        {b2x.formatHtml(page.content.body.banner.title)}
                      </h1>
                    )}
                    {page?.content?.body.banner?.subtitle && (
                      <h5 className={classnames(`h1 fw-light text-${page.content.body.banner.subtitleColor}`)}>
                        {b2x.formatHtml(page.content.body.banner.subtitle)}
                      </h5>
                    )}
                  </div>
                </div>
              </Container>
            </div>
          </div>

          {MobileSearchFiltersOffcanvas}
          <section className="search-content pt-3 pt-lg-5">
            <Container>
              <b2x.Row>
                <b2x.Col size={{ xl: 3, xs: 12 }}>
                  <b2x.SearchFormHelper basePath={window.location.pathname} searchResult={searchResult} submitOnChange>
                    {({ fieldsHelper, formik }) => {
                      return (
                        <>
                          <div className="d-grid d-sm-block d-xl-none mb-3">
                            <Button
                              className="px-2"
                              onClick={showMobileSearchFiltersOffcanvas}
                              type="button"
                              variant="secondary"
                            >
                              <div className="d-flex justify-content-between align-items-center w-100">
                                <div className="me-3">
                                  <Icon className="me-2" name="filter" size={20} />
                                  {t('misc.filterAndOrderBy')}
                                </div>
                                {fieldsHelper.activeFilters.length > 0 && (
                                  <div
                                    className="bg-white text-black rounded-circle px-2 small d-flex align-items-center justify-content-center"
                                    style={{ height: '20px', width: '20px' }}
                                  >
                                    {fieldsHelper.activeFilters.length > 1
                                      ? fieldsHelper.activeFilters.length - 1
                                      : fieldsHelper.activeFilters.length}
                                  </div>
                                )}
                              </div>
                            </Button>
                          </div>
                          <b2x.Div className="search-form-active-filter d-none d-xl-block">
                            {fieldsHelper.activeFilters.length > 0 && (
                              <b2x.Row className="mb-3" gap={{ md: 1, xs: 2 }}>
                                {fieldsHelper.activeFilters.map((activeFilter, index) => (
                                  <b2x.Col
                                    key={activeFilter.filter.name + activeFilter.filter.id}
                                    size={{ lg: 12, xs: 'auto' }}
                                  >
                                    {activeFilter.fromSimpleSearch && (
                                      <small className="py-1">{t('misc.youSearchedFor')}</small>
                                    )}
                                    <div className="d-grid">
                                      <Button
                                        className={classnames('btn-sm extra-small fw-normal py-1 px-2', {
                                          'text-uppercase':
                                            fieldsHelper.activeFilters.length > 1 &&
                                            fieldsHelper.activeFilters.length === index + 1,
                                        })}
                                        iconEnd={{ name: 'delete', size: 14 }}
                                        justifyContent="between"
                                        label={activeFilter.filter.name}
                                        onClick={activeFilter.handleClick}
                                        type="button"
                                        variant="gray-100"
                                      />
                                    </div>
                                  </b2x.Col>
                                ))}
                              </b2x.Row>
                            )}
                          </b2x.Div>
                          <div className="d-none d-xl-block">
                            <b2x.SearchFilters excludeFacets={_excludeFacets} fieldsHelper={fieldsHelper} />
                          </div>
                        </>
                      );
                    }}
                  </b2x.SearchFormHelper>
                </b2x.Col>
                <b2x.Col size={{ xl: 9, xs: 12 }}>
                  {searchResult?.itemsCount && (
                    <b2x.Row className="justify-content-between mb-3 extra-small">
                      <b2x.Col size={'auto'} style={{ alignItems: 'center', display: 'flex' }}>
                        <span>{t('misc.searchItemResult', { count: searchResult.itemsCount })}</span>
                      </b2x.Col>
                      <b2x.Col size={'auto'}>
                        <SortingOptionsDropdown className="d-none d-lg-block" searchResult={searchResult} />
                        <div className="d-block d-md-none">
                          <div className="hstack gap-2">
                            <Button
                              className="p-2"
                              iconStart={{ name: 'two-column-display', size: 14 }}
                              // eslint-disable-next-line react/jsx-no-bind
                              onClick={() => handleSetMobileListingColumn('multiple')}
                              variant="blank"
                            />
                            <div className="vr"></div>
                            <Button
                              className="p-2"
                              iconStart={{ name: 'one-column-display', size: 14 }}
                              // eslint-disable-next-line react/jsx-no-bind
                              onClick={() => handleSetMobileListingColumn('single')}
                              variant="blank"
                            />
                          </div>
                        </div>
                      </b2x.Col>
                    </b2x.Row>
                  )}
                  {searchResult && searchResult.items && (
                    <b2x.Listing name="Search page" products={searchResult.items}>
                      <b2x.ProductsTiles
                        className="mb-5"
                        enableExtraInfo
                        gap={{ lg: 4, xs: 2 }}
                        products={searchResult.items.map((product) => ({ product: product }))}
                        productsPerRow={{
                          lg: 3,
                          md: 2,
                          sm: mobileColumn === 'multiple' ? 2 : 1,
                          xl: 3,
                          xs: mobileColumn === 'multiple' ? 2 : 1,
                          xxl: 3,
                        }}
                      />
                    </b2x.Listing>
                  )}
                  {searchResult && (
                    <b2x.Div display="flex" justifyContent={{ lg: 'end', xs: 'center' }}>
                      <b2x.Pagination
                        currentPage={searchResult.pageNumber}
                        pageOffset={2}
                        showDots
                        singleStepNavigation
                        totalPages={searchResult.pagesCount}
                      />
                    </b2x.Div>
                  )}
                </b2x.Col>
              </b2x.Row>
            </Container>
          </section>
        </Page>
      ) : (
        <>
          {useCase !== undefined && (
            <Page className="private-sales-page" thingsToLoadBeforePageReady={[page, useCase]}>
              {page?.content?.body.title && page.content.body.title[useCase] && (
                <Container>
                  <b2x.Row className="justify-content-center">
                    <b2x.Col size={{ lg: 10, md: 10, sm: 11 }}>
                      <h2 className="text-center mb-3 mb-md-4 fw-bold">{page.content.body.title[useCase]}</h2>
                    </b2x.Col>
                  </b2x.Row>
                </Container>
              )}

              <div className="d-flex align-items-center">
                {page?.content?.body.image && (
                  <div className={classnames('h-100 w-100')}>
                    <b2x.ImageFromContentV2 {...page.content.body.image} className="w-100" fluid />
                  </div>
                )}
              </div>

              {countdownForLoggedUsers && (
                <Container>
                  <b2x.Row className="justify-content-center">
                    <b2x.Col size={{ lg: 7, md: 9, sm: 10, xl: 6, xs: 11, xxl: 5 }}>
                      <b2x.Div
                        className={classnames('position-relative', {
                          'mt-n4 mt-md-n5': !(
                            countdownForLoggedUsers.days === 0 &&
                            countdownForLoggedUsers.hours === 0 &&
                            countdownForLoggedUsers.minutes === 0 &&
                            countdownForLoggedUsers.seconds === 0
                          ),
                        })}
                      >
                        {!(
                          countdownForLoggedUsers.days === 0 &&
                          countdownForLoggedUsers.hours === 0 &&
                          countdownForLoggedUsers.minutes === 0 &&
                          countdownForLoggedUsers.seconds === 0
                        ) && (
                          <Countdown
                            classNames="mb-5"
                            days={countdownForLoggedUsers.days}
                            hours={countdownForLoggedUsers.hours}
                            minutes={countdownForLoggedUsers.minutes}
                            seconds={countdownForLoggedUsers.seconds}
                          />
                        )}
                        {page?.content?.body.copy && page.content.body.copy[useCase] && (
                          <b2x.Div className="h2" marginBottom={4} marginTop={4} textAlign={'center'}>
                            {b2x.formatHtml(page.content.body.copy[useCase])}
                          </b2x.Div>
                        )}
                      </b2x.Div>
                    </b2x.Col>
                  </b2x.Row>
                </Container>
              )}

              {!session?.userLogged ? (
                <Container>
                  {page?.content?.body.hashtag && (
                    <b2x.Row className="justify-content-center">
                      <b2x.Col size={{ xl: 8, xs: 12 }}>
                        <div className="h2 my-lg-5 my-3 text-center text-primary">
                          {b2x.formatHtml(page.content.body.hashtag)}
                        </div>
                      </b2x.Col>
                    </b2x.Row>
                  )}
                  <b2x.Row className="justify-content-center">
                    <b2x.Col size={{ md: 6, xl: 4, xs: 12 }}>
                      <div className="h-100 pb-3 pb-md-5">
                        <Box fullHeight>
                          <div className="text-center">
                            <h3 className="h2 fw-bold mb-4">{t('misc.privateSales.titleLoginBox')}</h3>
                            <p className="mb-3">{t('misc.privateSales.subTitleLoginBox')}</p>
                          </div>
                          <b2x.LoginForm />
                        </Box>
                      </div>
                    </b2x.Col>
                    <b2x.Col size={{ md: 6, xl: 4, xs: 12 }}>
                      <div className="h-100 pb-3 pb-md-5">
                        <Box fullHeight>
                          <div className="text-center">
                            <h3 className="h2 fw-bold mb-4">{t('misc.privateSales.titleRegisterBox')}</h3>
                            <p className="mb-3">{t('misc.privateSales.subTitleRegisterBox')}</p>
                          </div>
                          <div className="d-grid">
                            <b2x.router.Link
                              className="btn btn-primary registration-cta"
                              closeModal
                              to={getFixedPagePath('register')}
                            >
                              {t('account.register')}
                            </b2x.router.Link>
                          </div>
                        </Box>
                      </div>
                    </b2x.Col>
                  </b2x.Row>
                </Container>
              ) : (
                <Container>
                  <b2x.Row className="justify-content-center">
                    <b2x.Col size={{ md: 6, xl: 6, xs: 12 }}>
                      <div className="text-center my-lg-5 my-4">
                        <b2x.router.Link className="small btn btn-primary" to="/account/area/wishlist">
                          Prepara la tua wishlist
                        </b2x.router.Link>
                      </div>
                    </b2x.Col>
                  </b2x.Row>
                </Container>
              )}
            </Page>
          )}
        </>
      )}
      ;
    </>
  );
};

interface SortingOptionsDropdownProps {
  className?: string;
  searchResult?: b2x.SearchProductsApiDto;
}

const SortingOptionsDropdown = ({ className, searchResult }: SortingOptionsDropdownProps) => {
  const { sortingOptions } = b2x.useSortingOptions([
    { orderBy: 'PRICE', orderingType: 'ASC' },
    { orderBy: 'PRICE', orderingType: 'DESC' },
  ]);

  return (
    <>
      {searchResult && (
        <b2x.Dropdown
          buttonClassname="btn-sm extra-small fw-normal"
          className={classnames('order-dropdown ps-3 border-start border-gray-100', className)}
          label={b2x.formatHtml(
            `${t('misc.orderBy')}: <b>${sortingOptions.find((sortingItem) => sortingItem.active)?.label}</b>`
          )}
          variant="blank"
        >
          {sortingOptions.map((sortingOption) => (
            <b2x.DropdownItem active={sortingOption.active} key={sortingOption.label} onClick={sortingOption.onClick}>
              <span>{sortingOption.label}</span>
            </b2x.DropdownItem>
          ))}
        </b2x.Dropdown>
      )}
    </>
  );
};
