// eslint-disable-next-line no-restricted-imports
import { api as _api, CheckoutApiType, ShopApiDto } from '@b2x/storefront-api-js-client/src';
import { Config as ApiConfig } from '@b2x/storefront-api-js-client/src/utils/util';
import _ from 'lodash';
import React from 'react';
import { SetRequired } from 'type-fest';
import * as yup from 'yup';
import { setLocale } from 'yup';

import { AccountOffcanvasProps, AccountOffcanvasVariants } from './AccountOffcanvas';
import { AlertFromDtoProps, AlertFromDtoVariants } from './AlertFromDto';
import { analytics as _analytics } from './analytics/analytics';
import { BaimInitProps } from './analytics/baim';
import { EmarsysWebExtendInitProps } from './analytics/emarsysWebExtend';
import { FacebookPixelInitProps } from './analytics/facebookPixel';
import { GoogleAdsInitProps } from './analytics/googleAds';
import { GoogleAnalytics4Props } from './analytics/googleAnalytics4';
import { GoogleTagManagerInitProps } from './analytics/googleTagManager';
import { AssembledComponentSkuTileProps, AssembledComponentSkuTileVariants } from './AssembledComponentSkuTile';
import { BestPriceModalProps, BestPriceModalVariants, BestPriceProps, BestPriceVariants } from './BestPrice';
import { BreadcrumbProps, BreadcrumbVariants } from './Breadcrumb';
import { ButtonProps, ButtonVariants } from './Button';
import { CartOffcanvasProps, CartOffcanvasVariants } from './CartOffcanvas';
import { CartSkuProps, CartSkuVariants } from './CartSku';
import { CircleProgressBarProps, CircleProgressBarVariants } from './CircleProgressBar';
import { ContainerProps, ContainerVariants } from './Container';
import { CustomRadioCheckProps, CustomRadioCheckVariants } from './CustomRadioCheck';
import { env } from './env';
import { FetchingProps } from './Fetching';
import { AddressFieldsetProps, AddressFieldsetVariants } from './form/AddressFieldset';
import { AddToCartFormProps, AddToCartFormVariants } from './form/AddToCartForm';
import { CartAddressesFormProps, CartAddressesFormVariants } from './form/CartAddressesForm';
import {
  CartAddressFromAddressBookFormProps,
  CartAddressFromAddressBookFormVariants,
} from './form/CartAddressFromAddressBookForm';
import { CartEmailFormProps, CartEmailFormVariants } from './form/CartEmailForm';
import { CartPaymentMethodsFormProps, CartPaymentMethodsFormVariants } from './form/CartPaymentMethodsForm';
import { CartShippingProfilesFormProps, CartShippingProfilesFormVariants } from './form/CartShippingProfilesForm';
import { CartSkuQuantityFormProps, CartSkuQuantityFormVariants } from './form/CartSkuQuantityForm';
import { ChangePasswordByTokenFormProps, ChangePasswordByTokenFormVariants } from './form/ChangePasswordByTokenForm';
import { ChangePasswordFormProps, ChangePasswordFormVariants } from './form/ChangePasswordForm';
import { ConsentsFormProps, ConsentsFormVariants } from './form/ConsentsForm';
import { CustomerAddressFormProps, CustomerAddressFormVariants } from './form/CustomerAddressForm';
import { CustomerCareFormProps, CustomerCareFormVariants } from './form/CustomerCareForm';
import { CustomerFormProps, CustomerFormVariants } from './form/CustomerForm';
import { ExampleFormProps, ExampleFormVariants } from './form/ExampleForm';
import { ForgotPasswordFormProps, ForgotPasswordFormVariants } from './form/ForgotPasswordForm';
import { GenericCouponFormProps, GenericCouponFormVariants } from './form/GenericCouponForm';
import { GiftCardFormProps, GiftCardFormVariants } from './form/GiftCardForm';
import { InvoiceFieldsetProps, InvoiceFieldsetVariants } from './form/InvoiceFieldset';
import { LocaleFormProps, LocaleFormVariants } from './form/LocaleForm';
import { LoginFormProps, LoginFormVariants } from './form/LoginForm';
import { NewsletterFormProps, NewsletterFormVariants } from './form/NewsletterForm';
import { NewsletterRedirectFormProps, NewsletterRedirectFormVariants } from './form/NewsletterRedirectForm';
import { OrderGuestFormProps, OrderGuestFormVariants } from './form/OrderGuestForm';
import { PersonalCouponsFormProps, PersonalCouponsFormVariants } from './form/PersonalCouponsForm';
import { ProductsPerPageFormProps, ProductsPerPageFormVariants } from './form/ProductsPerPageForm';
import { RestartPaymentFormProps, RestartPaymentFormVariants } from './form/RestartPaymentForm';
import { ReviewFormModalProps, ReviewFormModalVariants, ReviewFormProps, ReviewFormVariants } from './form/ReviewForm';
import { SearchFormProps, SearchFormVariants } from './form/SearchForm';
import { ShopSearchFormProps, ShopSearchFormVariants } from './form/ShopSearchForm';
import { SimpleSearchFormProps, SimpleSearchFormVariants } from './form/SimpleSearchForm';
import { SmartOrderFormProps, SmartOrderFormVariants } from './form/SmartOrderForm';
import { StartPaymentFormProps, StartPaymentFormVariants } from './form/StartPaymentForm';
import { TaxBenefitsFormProps, TaxBenefitsFormVariants } from './form/TaxBenefitsForm';
import { HeaderCheckoutProps, HeaderCheckoutVariants } from './HeaderCheckout';
//import { TaxBenefitsFormProps, TaxBenefitsFormVariants } from './form/TaxBenefitsForm';
import { SupportedLanguage } from './i18n/i18n';
import yupLocale from './i18n/yupLocale';
import { AspectRatio } from './ImagePlaceholder';
import { InstallmentPaymentBannerProps } from './InstallmentPaymentBanner';
import { LoadingProps, LoadingVariants } from './Loading';
import { LoadingOverlayProps, LoadingOverlayVariants } from './LoadingOverlay';
import { ModalFooterProps, ModalFooterVariants, ModalHeaderProps, ModalHeaderVariants } from './Modal';
import { OffcanvasHeaderProps, OffcanvasHeaderVariants } from './Offcanvas';
import { OrderDetailsProps, OrderDetailsVariants } from './OrderDetails';
import { OrdersTableProps, OrdersTableVariants } from './OrdersTable';
import { AccountHomePageProps, AccountHomePageVariants } from './pages/AccountHomePage';
import { AccountPageProps, AccountPageVariants } from './pages/AccountPage';
import {
  AccountCouponsSubpageProps,
  AccountCouponsSubpageVariants,
} from './pages/accountSubpages/AccountCouponsSubpage';
import {
  AccountCreditCardsSubpageProps,
  AccountCreditCardsSubpageVariants,
} from './pages/accountSubpages/AccountCreditCardsSubpage';
import { AccountHelpSubpageProps, AccountHelpSubpageVariants } from './pages/accountSubpages/AccountHelpSubpage';
import { AccountInfoSubpageProps, AccountInfoSubpageVariants } from './pages/accountSubpages/AccountInfoSubpage';
import {
  AccountLoyaltySubpageProps,
  AccountLoyaltySubpageVariants,
} from './pages/accountSubpages/AccountLoyaltySubpage';
import { AccountOrdersSubpageProps, AccountOrdersSubpageVariants } from './pages/accountSubpages/AccountOrdersSubpage';
import {
  AccountPrivacySubpageProps,
  AccountPrivacySubpageVariants,
} from './pages/accountSubpages/AccountPrivacySubpage';
import { AccountQuickReorderSubpageProps, AccountQuickReorderSubpageVariants } from './pages/accountSubpages/AccountQuickReorderSubpage';
import {
  AccountReferralSubpageProps,
  AccountReferralSubpageVariants,
} from './pages/accountSubpages/AccountReferralSubpage';
import {
  AccountTaxBenefitsSubpageProps,
  AccountTaxBenefitsSubpageVariants,
} from './pages/accountSubpages/AccountTaxBenefitsSubpage';
import {
  AccountWishlistSubpageProps,
  AccountWishlistSubpageVariants,
} from './pages/accountSubpages/AccountWishlistSubpage';
import { CartPageProps, CartPageVariants } from './pages/CartPage';
import { CheckoutPageProps, CheckoutPageVariants } from './pages/CheckoutPage';
import { CheckoutAddressStepProps, CheckoutAddressStepVariants } from './pages/checkoutSteps/CheckoutAddressStep';
import { CheckoutEmailStepProps, CheckoutEmailStepVariants } from './pages/checkoutSteps/CheckoutEmailStep';
import {
  CheckoutPaymentMethodStepProps,
  CheckoutPaymentMethodStepVariants,
} from './pages/checkoutSteps/CheckoutPaymentMethodStep';
import { CheckoutSummaryStepProps, CheckoutSummaryStepVariants } from './pages/checkoutSteps/CheckoutSummaryStep';
import { ServicePageProps, ServicePageVariants } from './pages/ServicePage';
import {
  ServiceContactsSubpageProps,
  ServiceContactsSubpageVariants,
} from './pages/serviceSubpages/ServiceContactsSubpage';
import { ServiceCookieSubpageProps, ServiceCookieSubpageVariants } from './pages/serviceSubpages/ServiceCookieSubpage';
import { ServiceFaqSubpageProps, ServiceFaqSubpageVariants } from './pages/serviceSubpages/ServiceFaqSubpage';
import {
  ServiceGenericSubpageProps,
  ServiceGenericSubpageVariants,
} from './pages/serviceSubpages/ServiceGenericSubpage';
import { ServiceIndexSubpageProps, ServiceIndexSubpageVariants } from './pages/serviceSubpages/ServiceIndexSubpage';
import {
  ServicePrivacySubpageProps,
  ServicePrivacySubpageVariants,
} from './pages/serviceSubpages/ServicePrivacySubpage';
import {
  ServiceSearchOrderSubpageProps,
  ServiceSearchOrderSubpageVariants,
} from './pages/serviceSubpages/ServiceSearchOrderSubpage';
import { PageWithSubpageHeaderProps, PageWithSubpageHeaderVariants } from './PageWithSubpageHeader';
import { PayPalCheckoutProps, PayPalCheckoutVariants } from './PayPalCheckout';
import { PriceBlockProps, PriceBlockVariants } from './PriceBlock';
import { ProductReviewsProps, ProductReviewsVariants } from './ProductReviews';
import { ProductStickyFooterProps, ProductStickyFooterVariants } from './ProductStickyFooter';
import { ProductTileProps, ProductTileVariants } from './ProductTile';
import { ProductTileBasicInfoProps, ProductTileBasicInfoVariants } from './ProductTileBasicInfo';
import { ProductTileExtraInfoProps, ProductTileExtraInfoVariants } from './ProductTileExtraInfo';
import { QuantityInputGroupProps, QuantityInputGroupVariants } from './QuantityInputGroup';
import { RatingDistributionProps, RatingDistributionVariants } from './reviews/RatingDistribution';
import { RatingStarProps, RatingStarVariants } from './reviews/RatingStar';
import { RatingStarsProps, RatingStarsVariants } from './reviews/RatingStars';
import { ReviewProps, ReviewVariants } from './reviews/Review';
import { ReviewItemListProps, ReviewItemListVariants } from './reviews/ReviewItemList';
import { ReviewsActionsProps, ReviewsActionsVariants } from './reviews/ReviewsActions';
import { ReviewsFiltersProps, ReviewsFiltersVariants } from './reviews/ReviewsFilter';
import { SearchFiltersProps, SearchFiltersVariants } from './SearchFilters';
import { SharingModalProps, SharingModalVariants } from './SharingModal';
import { SummaryReviewsPreviewProps, SummaryReviewsPreviewVariants } from './SummaryReviewsPreview';
import { TestProps, TestVariants } from './Test';
import { TopSuggestionsBoxProps, TopSuggestionsBoxVariants } from './TopSuggestionsBox';
import { PayPalCheckoutConfig } from './usePayPalCheckout';

export type UI = 'bs5';

interface Config<
  AdditionalPropertiesFormValues,
  AdditionalPropertiesValidationSchema,
  CustomPropertiesFormValues,
  CustomPropertiesValidationSchema,
  ThemeColor,
  ButtonVariant,
  IconName,
  IconSize
> {
  analytics?: {
    baim?: BaimInitProps;
    emarsysWebExtend?: EmarsysWebExtendInitProps;
    facebook?: FacebookPixelInitProps;
    googleAds?: GoogleAdsInitProps;
    googleAnalytics4?: GoogleAnalytics4Props;
    googleTagManager?: GoogleTagManagerInitProps;
  };
  api?: Omit<ApiConfig, 'baseUrl'>;
  cartBlockingAlerts?: Array<CheckoutApiType>;
  consentMode?: boolean;
  cookiePrefix: string;
  cookiebot?: {
    id: string;
  };
  defaultButtonVariant: ButtonVariant;
  // countriesLocalesMap?: Record<string, string>;
  enableBestPrice?: boolean;
  enableCientSideComingSoonManagement?: boolean;
  enableProductAvailabilityEmailNotification?: boolean;
  enableQuickReorder?: boolean;
  enableTaxBenefits?: boolean;
  // customComponents?: CustomComponents;
  enableWishlist?: boolean;
  forceImageDensity?: 1 | 1.5 | 2;
  form?: {
    buttons: {
      cancel?: {
        defaultVariant: ButtonVariant;
      };
      submit?: {
        defaultVariant: ButtonVariant;
      };
    };
    showPlaceholder?: boolean;
  };
  futureFashion3DViewer?: {
    customerId: string;
  };
  googleMaps?: {
    apiKey: string;
  };
  // Ho mainCategories quando ho più alberi di navigazione (uomo, donna, bambino...) che vanno trattati in maniera particolare.
  hasMainCategories?: boolean;
  hotjar?: {
    id: string;
  };
  i18n: SetRequired<Partial<Record<SupportedLanguage, Record<string, unknown>>>, 'en'>;
  icons?: {
    arrow?: {
      bottom?: IconName;
      left?: IconName;
      right?: IconName;
      top?: IconName;
    };
    cart?: IconName;
    cartCampaign?: IconName;
    close: IconName;
    configurator: IconName;
    decrement?: IconName;
    discount?: IconName;
    download: IconName;
    email?: IconName;
    increment?: IconName;
    info?: IconName;
    logout: IconName;
    loyalty?: IconName;
    passwordToggle?: {
      hidden: IconName;
      visible: IconName;
    };
    share?: IconName;
    shipping?: IconName;
    slider?: {
      arrow?: {
        left?: IconName;
        right?: IconName;
      };
    };
  };
  imagePlaceholder?: {
    backgroundColor?: string;
  };
  includeFreeShippingInSavingAmount?: boolean;
  installmentPaymentBanner?: InstallmentPaymentBannerProps;
  iubenda?: {
    cookiePolicyId: string;
    lang: string;
    siteId: string;
    useSiteId?: boolean;
  };
  keepUselessLocaleTokenInPath?: boolean;
  locale?: {
    default?: SupportedLanguage;
  };
  magnoliaProject?: {
    cookiePrefix: string;
  };
  oneTrust?: {
    domainScript: string;
  };
  payPalCheckout?: PayPalCheckoutConfig;
  paypal?: {
    merchantClientId?: string;
  };
  persistentSession?: boolean;
  preRelease?: boolean;
  preselectFirstAvailableSku?: boolean;
  preselectPreferredSku?: boolean;
  productImageAspectRatio?: number | AspectRatio;
  productMaxQuantity?: number;
  productVariantAttributesCodes?: Array<string>;
  projectName: string;
  // Se abilitato, salva alcune risorse (come contenuti e menu) nel session storage, per evitare di chiederli ogni volta
  saveResourcesInSession?: boolean;
  shippingCountry?: {
    forceDefault?: boolean;
    forced?: 'IT';
  };
  shopNameResolver?(shop: ShopApiDto): string | undefined;
  storeId: string;
  ui: UI;
  variants: Variants<
    AdditionalPropertiesFormValues,
    AdditionalPropertiesValidationSchema,
    CustomPropertiesFormValues,
    CustomPropertiesValidationSchema,
    ThemeColor,
    ButtonVariant,
    IconName,
    IconSize
  >;
  video?: {
    PlayIcon?: React.FunctionComponent;
  };
  whatsAppClickToChat?: number;
  widgetMode?: boolean;
}

export interface Variants<
  AdditionalPropertiesFormValues,
  AdditionalPropertiesValidationSchema,
  CustomPropertiesFormValues,
  CustomPropertiesValidationSchema,
  ThemeColor,
  ButtonVariant,
  IconName,
  IconSize
> {
  AccountCouponsSubpage?: AccountCouponsSubpageVariants | React.FunctionComponent<AccountCouponsSubpageProps>;
  AccountCreditCardsSubpage?:
    | AccountCreditCardsSubpageVariants
    | React.FunctionComponent<AccountCreditCardsSubpageProps>;
  AccountHelpSubpage?: AccountHelpSubpageVariants | React.FunctionComponent<AccountHelpSubpageProps>;
  AccountHomePage?: AccountHomePageVariants | React.FunctionComponent<AccountHomePageProps>;
  AccountInfoSubpage?: AccountInfoSubpageVariants | React.FunctionComponent<AccountInfoSubpageProps>;
  AccountLoyaltySubpage?: AccountLoyaltySubpageVariants | React.FunctionComponent<AccountLoyaltySubpageProps>;
  AccountOffcanvas?: AccountOffcanvasVariants | React.FunctionComponent<AccountOffcanvasProps>;
  AccountOrdersSubpage?: AccountOrdersSubpageVariants | React.FunctionComponent<AccountOrdersSubpageProps>;
  AccountPage?: AccountPageVariants | React.FunctionComponent<AccountPageProps>;
  AccountPrivacySubpage?: AccountPrivacySubpageVariants | React.FunctionComponent<AccountPrivacySubpageProps>;
  AccountQuickReorderSubpage?: AccountQuickReorderSubpageVariants | React.FunctionComponent<AccountQuickReorderSubpageProps>;
  AccountReferralSubpage?: AccountReferralSubpageVariants | React.FunctionComponent<AccountReferralSubpageProps>;
  AccountTaxBenefitsSubpage?:
    | AccountTaxBenefitsSubpageVariants
    | React.FunctionComponent<AccountTaxBenefitsSubpageProps>;
  AccountWishlistSubpage?: AccountWishlistSubpageVariants | React.FunctionComponent<AccountWishlistSubpageProps>;
  AddToCartForm?: AddToCartFormVariants | React.FunctionComponent<AddToCartFormProps>;
  AddressFieldset?: AddressFieldsetVariants | React.FunctionComponent<AddressFieldsetProps>;
  AlertFromDto?: AlertFromDtoVariants | React.FunctionComponent<AlertFromDtoProps<IconName, IconSize>>;
  AssembledComponentSkuTile?:
    | AssembledComponentSkuTileVariants
    | React.FunctionComponent<AssembledComponentSkuTileProps>;
  BestPrice?: BestPriceVariants | React.FunctionComponent<BestPriceProps>;
  BestPriceModal?: BestPriceModalVariants | React.FunctionComponent<BestPriceModalProps>;
  Breadcrumb?: BreadcrumbVariants | React.FunctionComponent<BreadcrumbProps>;
  Button?: ButtonVariants | React.FunctionComponent<ButtonProps<ButtonVariant, IconName, IconSize>>;
  CartAddressFromAddressBookForm?:
    | CartAddressFromAddressBookFormVariants
    | React.FunctionComponent<CartAddressFromAddressBookFormProps>;
  CartAddressesForm?: CartAddressesFormVariants | React.FunctionComponent<CartAddressesFormProps>;
  CartEmailForm?: CartEmailFormVariants | React.FunctionComponent<CartEmailFormProps>;
  CartOffcanvas?: CartOffcanvasVariants | React.FunctionComponent<CartOffcanvasProps>;
  CartPage?: CartPageVariants | React.FunctionComponent<CartPageProps>;
  CartPaymentMethodsForm?: CartPaymentMethodsFormVariants | React.FunctionComponent<CartPaymentMethodsFormProps>;
  CartShippingProfilesForm?: CartShippingProfilesFormVariants | React.FunctionComponent<CartShippingProfilesFormProps>;
  CartSku?: CartSkuVariants | React.FunctionComponent<CartSkuProps>;
  CartSkuQuantityForm?: CartSkuQuantityFormVariants | React.FunctionComponent<CartSkuQuantityFormProps>;
  ChangePasswordByTokenForm?:
    | ChangePasswordByTokenFormVariants
    | React.FunctionComponent<ChangePasswordByTokenFormProps>;
  ChangePasswordForm?: ChangePasswordFormVariants | React.FunctionComponent<ChangePasswordFormProps>;
  CheckoutAddressStep?: CheckoutAddressStepVariants | React.FunctionComponent<CheckoutAddressStepProps>;
  CheckoutEmailStep?: CheckoutEmailStepVariants | React.FunctionComponent<CheckoutEmailStepProps>;
  CheckoutPage?: CheckoutPageVariants | React.FunctionComponent<CheckoutPageProps>;
  CheckoutPaymentMethodStep?:
    | CheckoutPaymentMethodStepVariants
    | React.FunctionComponent<CheckoutPaymentMethodStepProps>;
  CheckoutSummaryStep?: CheckoutSummaryStepVariants | React.FunctionComponent<CheckoutSummaryStepProps>;
  CircleProgressBar?: CircleProgressBarVariants | React.FunctionComponent<CircleProgressBarProps>;
  ConsentsForm?: ConsentsFormVariants | React.FunctionComponent<ConsentsFormProps>;
  Container?: ContainerVariants | React.FunctionComponent<ContainerProps>;
  CustomRadioCheck?: CustomRadioCheckVariants | React.FunctionComponent<CustomRadioCheckProps>;
  CustomerAddressForm?: CustomerAddressFormVariants | React.FunctionComponent<CustomerAddressFormProps>;
  CustomerCareForm?: CustomerCareFormVariants | React.FunctionComponent<CustomerCareFormProps>;
  CustomerForm?:
    | CustomerFormVariants
    | React.FunctionComponent<
        CustomerFormProps<
          AdditionalPropertiesFormValues,
          AdditionalPropertiesValidationSchema,
          CustomPropertiesFormValues,
          CustomPropertiesValidationSchema,
          AdditionalPropertiesFormValues,
          AdditionalPropertiesValidationSchema,
          CustomPropertiesFormValues,
          CustomPropertiesValidationSchema
        >
      >;
  ExampleForm?: ExampleFormVariants | React.FunctionComponent<ExampleFormProps>;
  Fetching?: React.FunctionComponent<FetchingProps<unknown>>;
  ForgotPasswordForm?: ForgotPasswordFormVariants | React.FunctionComponent<ForgotPasswordFormProps>;
  GenericCouponForm?: GenericCouponFormVariants | React.FunctionComponent<GenericCouponFormProps>;
  GiftCardForm?: GiftCardFormVariants | React.FunctionComponent<GiftCardFormProps>;
  HeaderCheckout?: HeaderCheckoutVariants | React.FunctionComponent<HeaderCheckoutProps>;
  InvoiceFieldset?: InvoiceFieldsetVariants | React.FunctionComponent<InvoiceFieldsetProps>;
  Loading?: LoadingVariants | React.FunctionComponent<LoadingProps>;
  LoadingOverlay?: LoadingOverlayVariants | React.FunctionComponent<LoadingOverlayProps>;
  LocaleForm?: LocaleFormVariants | React.FunctionComponent<LocaleFormProps>;
  LoginForm?: LoginFormVariants | React.FunctionComponent<LoginFormProps>;
  ModalFooter?: ModalFooterVariants | React.FunctionComponent<ModalFooterProps>;
  ModalHeader?: ModalHeaderVariants | React.FunctionComponent<ModalHeaderProps>;
  NewsletterForm?:
    | NewsletterFormVariants
    | React.FunctionComponent<
        NewsletterFormProps<AdditionalPropertiesFormValues, AdditionalPropertiesValidationSchema>
      >;
  NewsletterRedirectForm?: NewsletterRedirectFormVariants | React.FunctionComponent<NewsletterRedirectFormProps>;
  OffcanvasHeader?: OffcanvasHeaderVariants | React.FunctionComponent<OffcanvasHeaderProps>;
  OrderDetails?: OrderDetailsVariants | React.FunctionComponent<OrderDetailsProps>;
  OrderGuestForm?: OrderGuestFormVariants | React.FunctionComponent<OrderGuestFormProps>;
  OrdersTable?: OrdersTableVariants | React.FunctionComponent<OrdersTableProps>;
  PageWithSubpageHeader?: PageWithSubpageHeaderVariants | React.FunctionComponent<PageWithSubpageHeaderProps>;
  PayPalCheckout?: PayPalCheckoutVariants | React.FunctionComponent<PayPalCheckoutProps>;
  PersonalCouponsForm?: PersonalCouponsFormVariants | React.FunctionComponent<PersonalCouponsFormProps>;
  // PersonalDataForm?:
  //   | PersonalDataFormVariants
  //   | React.FunctionComponent<
  //       CustomerFormProps<
  //         AdditionalPropertiesFormValues,
  //         AdditionalPropertiesValidationSchema,
  //         CustomPropertiesFormValues,
  //         CustomPropertiesValidationSchema,
  //         AdditionalPropertiesFormValues,
  //         AdditionalPropertiesValidationSchema,
  //         CustomPropertiesFormValues,
  //         CustomPropertiesValidationSchema
  //       >
  //     >;
  PriceBlock?: PriceBlockVariants | React.FunctionComponent<PriceBlockProps>;
  ProductReviews?: ProductReviewsVariants | React.FunctionComponent<ProductReviewsProps>;
  ProductStickyFooter?:
    | ProductStickyFooterVariants
    | React.FunctionComponent<ProductStickyFooterProps<ButtonVariant, IconName, IconSize>>;
  ProductTile?: ProductTileVariants | React.FunctionComponent<ProductTileProps>;
  ProductTileBasicInfo?: ProductTileBasicInfoVariants | React.FunctionComponent<ProductTileBasicInfoProps>;
  ProductTileExtraInfo?: ProductTileExtraInfoVariants | React.FunctionComponent<ProductTileExtraInfoProps>;
  ProductsPerPageForm?: ProductsPerPageFormVariants | React.FunctionComponent<ProductsPerPageFormProps>;
  QuantityInputGroup?:
    | QuantityInputGroupVariants
    | React.FunctionComponent<QuantityInputGroupProps<ButtonVariant, IconName, IconSize>>;
  RatingDistribution?: RatingDistributionVariants | React.FunctionComponent<RatingDistributionProps>;
  RatingStar?: RatingStarVariants | React.FunctionComponent<RatingStarProps>;
  RatingStars?: RatingStarsVariants | React.FunctionComponent<RatingStarsProps>;
  // RegistrationForm?:
  //   | RegistrationFormVariants
  //   | React.FunctionComponent<
  //       CustomerFormProps<
  //         AdditionalPropertiesFormValues,
  //         AdditionalPropertiesValidationSchema,
  //         CustomPropertiesFormValues,
  //         CustomPropertiesValidationSchema,
  //         AdditionalPropertiesFormValues,
  //         AdditionalPropertiesValidationSchema,
  //         CustomPropertiesFormValues,
  //         CustomPropertiesValidationSchema
  //       >
  //     >;
  RestartPaymentForm?: RestartPaymentFormVariants | React.FunctionComponent<RestartPaymentFormProps>;
  Review?: ReviewVariants | React.FunctionComponent<ReviewProps>;
  ReviewForm?: ReviewFormVariants | React.FunctionComponent<ReviewFormProps>;
  ReviewFormModal?: ReviewFormModalVariants | React.FunctionComponent<ReviewFormModalProps>;
  ReviewItemList?: ReviewItemListVariants | React.FunctionComponent<ReviewItemListProps>;
  ReviewsActions?: ReviewsActionsVariants | React.FunctionComponent<ReviewsActionsProps>;
  ReviewsFilters?: ReviewsFiltersVariants | React.FunctionComponent<ReviewsFiltersProps>;
  SearchFilters?: SearchFiltersVariants | React.FunctionComponent<SearchFiltersProps>;
  SearchForm?: SearchFormVariants | React.FunctionComponent<SearchFormProps>;
  ServiceContactsSubpage?: ServiceContactsSubpageVariants | React.FunctionComponent<ServiceContactsSubpageProps>;
  ServiceCookieSubpage?: ServiceCookieSubpageVariants | React.FunctionComponent<ServiceCookieSubpageProps>;
  ServiceFaqSubpage?: ServiceFaqSubpageVariants | React.FunctionComponent<ServiceFaqSubpageProps>;
  ServiceGenericSubpage?: ServiceGenericSubpageVariants | React.FunctionComponent<ServiceGenericSubpageProps>;
  ServiceIndexSubpage?: ServiceIndexSubpageVariants | React.FunctionComponent<ServiceIndexSubpageProps>;
  ServicePage?: ServicePageVariants | React.FunctionComponent<ServicePageProps>;
  ServicePrivacySubpage?: ServicePrivacySubpageVariants | React.FunctionComponent<ServicePrivacySubpageProps>;
  ServiceSearchOrderSubpage?:
    | ServiceSearchOrderSubpageVariants
    | React.FunctionComponent<ServiceSearchOrderSubpageProps>;
  SharingModal?: SharingModalVariants | React.FunctionComponent<SharingModalProps<ButtonVariant>>;
  ShopSearchForm?: ShopSearchFormVariants | React.FunctionComponent<ShopSearchFormProps>;
  SimpleSearchForm?: SimpleSearchFormVariants | React.FunctionComponent<SimpleSearchFormProps>;
  SmartOrderForm?: SmartOrderFormVariants | React.FunctionComponent<SmartOrderFormProps>;
  StartPaymentForm?: StartPaymentFormVariants | React.FunctionComponent<StartPaymentFormProps>;
  SummaryReviewsPreview?: SummaryReviewsPreviewVariants | React.FunctionComponent<SummaryReviewsPreviewProps>;
  TaxBenefitsForm?:
    | TaxBenefitsFormVariants
    | React.FunctionComponent<TaxBenefitsFormProps<CustomPropertiesFormValues, CustomPropertiesValidationSchema>>;
  Test?: TestVariants | React.FunctionComponent<TestProps>;
  TopSuggestionsBox?: TopSuggestionsBoxVariants | React.FunctionComponent<TopSuggestionsBoxProps>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export let appConfig: Config<any, yup.AnyObjectSchema, any, yup.AnyObjectSchema, string, string, string, number>;

export const init = <
  ThemeColor extends string,
  ButtonVarian extends string,
  IconName extends string,
  IconSize extends number
>({
  analytics,
  api,
  cartBlockingAlerts,
  consentMode = true,
  cookiebot,
  defaultButtonVariant,
  enableBestPrice = true,
  enableCientSideComingSoonManagement = false,
  enableProductAvailabilityEmailNotification = false,
  // countriesLocalesMap,
  enableQuickReorder = false,
  enableTaxBenefits = false,
  enableWishlist = true,
  forceImageDensity,
  form,
  futureFashion3DViewer,
  googleMaps,
  hasMainCategories = false,
  hotjar,
  i18n,
  icons,
  imagePlaceholder,
  includeFreeShippingInSavingAmount,
  installmentPaymentBanner,
  iubenda,
  keepUselessLocaleTokenInPath,
  magnoliaProject,
  oneTrust,
  paypal,
  persistentSession = true,
  preRelease = false,
  preselectFirstAvailableSku,
  preselectPreferredSku,
  productImageAspectRatio,
  productMaxQuantity = 99,
  projectName,
  saveResourcesInSession = false,
  shippingCountry,
  shopNameResolver,
  ui = 'bs5',
  variants,
  video,
  whatsAppClickToChat,
  widgetMode,
}: Omit<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Config<any, yup.AnyObjectSchema, any, yup.AnyObjectSchema, ThemeColor, ButtonVarian, IconName, IconSize>,
  'cookiePrefix' | 'storeId'
>) => {
  appConfig = {
    analytics,
    api,
    cartBlockingAlerts,
    consentMode,
    cookiePrefix: '',
    cookiebot,
    defaultButtonVariant,
    // countriesLocalesMap,
    enableBestPrice,
    enableCientSideComingSoonManagement,
    enableProductAvailabilityEmailNotification,
    enableQuickReorder,
    enableTaxBenefits,
    enableWishlist,
    forceImageDensity,
    form,
    futureFashion3DViewer,
    googleMaps,
    hasMainCategories,
    hotjar,
    i18n,
    icons,
    imagePlaceholder,
    includeFreeShippingInSavingAmount,
    installmentPaymentBanner,
    iubenda,
    keepUselessLocaleTokenInPath,
    magnoliaProject,
    oneTrust,
    paypal,
    persistentSession,
    preRelease,
    preselectFirstAvailableSku,
    preselectPreferredSku,
    productImageAspectRatio,
    productMaxQuantity,
    projectName,
    saveResourcesInSession,
    shippingCountry,
    shopNameResolver,
    storeId: '',
    ui,
    variants: variants as never,
    video,
    whatsAppClickToChat,
    widgetMode,
  };
  // i18nInit(appConfig.i18n);
  appConfig.api && _api.init({ ...appConfig.api, baseUrl: env.REACT_APP_API_BASE_URL });
  setLocale(yupLocale);
  consentMode && _analytics.init();
};

export const updateConfig = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update: Partial<Config<any, yup.AnyObjectSchema, any, yup.AnyObjectSchema, string, string, string, number>>
) => {
  appConfig = _.merge(appConfig, update);
};
